<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ $t("footer.copyright") }}</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            By <a href="https://www.digitalhouse-int.com/" target="_blank">Digital House International</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
